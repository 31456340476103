import MainView from '@/components/main-view'
export const currencyRoutes = [{
	path: '/',
	name: 'home',
	meta: {
		title: '订单充电桩',
		icon: 'el-icon-s-home',
		hideInMenu: true
	},
	component: MainView
}, {
	path: '/login',
	name: 'login',
	meta: {
		title: '登录',
		hideInMenu: true
	},
	component: () => import('@/views/login')
}, {
	path: '/page-403',
	name: 'page-403',
	meta: {
		title: 'page-403',
		hideInMenu: true
	},
	component: () => import('@/views/errors/403.vue')
}, {
	path: '/page-404',
	name: 'page-404',
	meta: {
		title: 'page-404',
		hideInMenu: true
	},
	component: () => import('@/views/errors/404.vue')
}, {
	path: '/page-500',
	name: 'page-500',
	meta: {
		title: 'page-500',
		hideInMenu: true
	},
	component: () => import('@/views/errors/500.vue')
}, {
	path: '/page-1000',
	name: 'page-1000',
	meta: {
		title: 'page-1000',
		hideInMenu: true
	},
	component: () => import('@/views/errors/1000.vue')
}, {
	path: '/page-1001',
	name: 'page-1001',
	meta: {
		title: 'page-1001',
		hideInMenu: true
	},
	component: () => import('@/views/errors/1001.vue')
}, {
	path: '/1002',
	name: '1002',
	meta: {
		title: '1002',
		hideInMenu: true
	},
	component: () => import('@/views/errors/1002.vue')
}]
export const asyncRoutes = [{
	path: '/device-management',
	name: 'device-management',
	meta: {
		title: '设备管理',
		icon: 'el-icon-cpu',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/device-device-list',
		name: 'device-device-list',
		meta: {
			title: '设备清单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/device-management/device')
	}, {
		path: '/device-device-add',
		name: 'device-device-add',
		meta: {
			title: '新增设备',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/device-management/device/add')
	}, {
		path: '/device-device-edit',
		name: 'device-device-edit',
		meta: {
			title: '编辑设备',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/device-management/device/edit')
	}, {
		path: '/device-device-plugs',
		name: 'device-device-plugs',
		meta: {
			title: '插座情况',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/device-management/device/plugs')
	}, {
		path: '/device-fee-list',
		name: 'device-fee-list',
		meta: {
			title: '费率信息',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/device-management/fee')
	}, {
		path: '/device-plug-list',
		name: 'device-plug-list',
		meta: {
			title: '插座信息',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/device-management/plug')
	}, {
		path: '/device-group-list',
		name: 'device-group-list',
		meta: {
			title: '站点管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/device-management/group')
	}, {
		path: '/device-group-add',
		name: 'device-group-add',
		meta: {
			title: '新建小区',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/device-management/group/add')
	}, {
		path: '/device-group-edit',
		name: 'device-group-edit',
		meta: {
			title: '编辑小区',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/device-management/group/edit')
	},{
		path: '/device-type-list',
		name: 'device-type-list',
		meta: {
			title: '设备类型',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/device-management/type')
	}, {
		path: '/device-repaire-list',
		name: 'device-repaire-list',
		meta: {
			title: '报修清单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/device-management/repaire')
	}, {
		path: '/device-seat-list',
		name: 'device-seat-list',
		meta: {
			title: '占位管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/device-management/seat')
	}, {
		path: '/device-seat-add',
		name: 'device-seat-add',
		meta: {
			title: '新建占位',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/device-management/seat/add')
	}, {
		path: '/device-seat-edit',
		name: 'device-seat-edit',
		meta: {
			title: '编辑占位',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/device-management/seat/edit')
	}, {
		path: '/device-label-list',
		name: 'device-label-list',
		meta: {
			title: '场站标签',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/device-management/label')
	}]
}, {
	path: '/nh-management',
	name: 'nh-management',
	meta: {
		title: '订单管理',
		icon: 'el-icon-crop',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/user-order-list',
		name: 'user-order-list',
		meta: {
			title: '消费订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/order')
	}, {
		path: '/user-recharge-list',
		name: 'user-recharge-list',
		meta: {
			title: '充值订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/recharge')
	}, {
		path: '/market-energy-shoporder',
		name: 'market-energy-shoporder',
		meta: {
			title: '商城订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/energy/shopOrder')
	}, {
		path: '/user-seatorder-list',
		name: 'user-seatorder-list',
		meta: {
			title: '占位订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/seatorder')
	}]
}, {
	path: '/user-management',
	name: 'user-management',
	meta: {
		title: '用户管理',
		icon: 'el-icon-user',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/user-fans-list',
		name: 'user-fans-list',
		meta: {
			title: '用户信息',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/fans')
	}, {
		path: '/market-group-score',
		name: 'market-group-score',
		meta: {
			title: '场站评分',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/group/Score'),
		children:[]
	}, {
		path: '/market-group-scorelist',
		name: 'market-group-scorelist',
		meta: {
			title: '单场站评分',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/group/ScoreList')
	}, {
		path: '/user-balance-list',
		name: 'user-balance-list',
		meta: {
			title: '余额同步记录',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/user-management/balance')
	},
		{
		path: '/user-refund-list',
		name: 'user-refund-list',
		meta: {
			title: '退款日志',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/refund')
	}, {
		path: '/user-giftlog-list',
		name: 'user-giftlog-list',
		meta: {
			title: '赠送金日志',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/giftlog')
	}, {
		path: '/user-fans-white',
		name: 'user-fans-white',
		meta: {
			title: '白名单',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/user-management/fans/white')
	}]
}, {
	path: '/cards-management',
	name: 'cards-management',
	meta: {
		title: '电卡管理',
		icon: 'el-icon-bank-card',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/cards-cards-list',
		name: 'cards-cards-list',
		meta: {
			title: '电卡信息',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/cards-management/cards')
	}, {
		path: '/cards-recharge-list',
		name: 'cards-recharge-list',
		meta: {
			title: '卡充值订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/cards-management/recharge')
	}, {
		path: '/cards-order-list',
		name: 'cards-order-list',
		meta: {
			title: '卡消费订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/cards-management/order')
	}, {
		path: '/cards-exception-list',
		name: 'cards-exception-list',
		meta: {
			title: '异常卡列表',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/cards-management/exception')
	}, {
		path: '/bill-group-list',
		name: 'bill-group-list',
		meta: {
			title: '卡现金收入',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/cards-management/bill/group')
	}, {
		path: '/cards-cards-logs',
		name: 'cards-cards-logs',
		meta: {
			title: '刷卡日志信息',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/cards-management/logs')
	}]
}, {
	path: '/agent-management',
	name: 'agent-management',
	meta: {
		title: '代理商管理',
		icon: 'el-icon-connection',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/agent-account-list',
		name: 'agent-account-list',
		meta: {
			title: '结算单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/agent-management/account')
	}, {
		path: '/agent-agent-list',
		name: 'agent-agent-list',
		meta: {
			title: '代理商',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/agent-management/agent')
	}]
}, {
	path: '/monitoring',
	name: 'monitoring',
	meta: {
		title: '电站监控',
		icon: 'el-icon-connection',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/monitoring-list',
		name: 'monitoring-list',
		meta: {
			title: '电站监控',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/monitoring/index')
	}, {
		path: '/monitoring-camera',
		name: 'monitoring-camera',
		meta: {
			title: '相机管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/monitoring/camera')
	}, {
		path: '/monitoring-detail',
		name: 'monitoring-detail',
		meta: {
			title: '电站详情',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/monitoring/detail')
	}, {
		path: '/monitoring-history',
		name: 'monitoring-history',
		meta: {
			title: '历史记录',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/monitoring/history')
	}]
}, {
	path: '/market-management',
	name: 'market-management',
	meta: {
		title: '营销管理',
		icon: 'el-icon-present',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/market-activity-action',
		name: 'market-activity-action',
		meta: {
			title: '营销活动',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/activity/action')
	}, {
		path: '/market.task_index',
		name: 'market-task-list',
		meta: {
			title: '任务配置',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/task')
	}, {
		path: '/market.plan_index',
		name: 'market-plan-list',
		meta: {
			title: '充值优惠',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/plan')
	}, {
		path: '/market.gift_index',
		name: 'market-gift-list',
		meta: {
			title: '充电度数',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/gift')
	}, {
		path: '/market.coupon_urlindex',
		name: 'market-coupon-url',
		meta: {
			title: '领券',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/couponurl')
	}, {
		path: '/market.activity_index',
		name: 'market-activity-list',
		meta: {
			title: '活动',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/activity')
	}, {
		path: '/market-qycard-detail',
		name: 'market-qycard-detail',
		meta: {
			title: '人员明细',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/qycard/detail')
	}, {
		path: '/market-card-record',
		name: 'market-card-record',
		meta: {
			title: '购买记录',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/card/record')
	},{
		path: '/market.chargecoupon_list',
		name: 'market-charge-list',
		meta: {
			title: '充电返券',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/charge')
	},{
		path: '/market-charge-record',
		name: 'market-charge-record',
		meta: {
			title: '返券记录',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/charge/record')
	},{
		path: '/market-charge-users',
		name: 'market-charge-users',
		meta: {
			title: '返券用户',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/charge/users')
	},{
		path: '/market.activitycommon_list',
		name: 'market-activitycommon-list',
		meta: {
			title: '车险活动',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/activitycommon')
	},{
		path: '/market.activitycommon_lottery',
		name: 'market-activitycommon-lottery',
		meta: {
			title: '抽奖活动',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/activitycommon/lottery')
	},{
		path: '/market-activitycommon-record',
		name: 'market-activitycommon-record',
		meta: {
			title: '车险活动用户',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/activitycommon/record')
	},{
		path: '/market-activitycommon-log',
		name: 'market-activitycommon-log',
		meta: {
			title: '抽奖用户',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/activitycommon/log')
	},{
		path: '/market-activitycommon-finish',
		name: 'market-activitycommon-finish',
		meta: {
			title: '抽奖次数',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/activitycommon/finish')
	},{
		path: '/market.activitycommon_invite',
		name: 'market-activitycommon-invite',
		meta: {
			title: '邀请有礼',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/activitycommon/invite')
	},{
		path: '/market-activitycommon-invitelog',
		name: 'market-activitycommon-invitelog',
		meta: {
			title: '邀请有礼用户',
			hideInMenu: true,
			closeTip: true,
			checkAccess: false
		},
		component: () => import('@/views/market-management/activitycommon/invitelog')
	},{
		path: '/market.group_list',
		name: 'market-group-list',
		meta: {
			title: '特惠站点',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/group')
	},{
		path: '/market.groupprice_index',
		name: 'market-group-price',
		meta: {
			title: '电站优惠',
			hideInMenu: true,
			closeTip: true,
		},
		component: () => import('@/views/market-management/group/price')
	},{
		path: '/market.activitycommon_newcar',
		name: 'market-activitycommon-newcar',
		meta: {
			title: '新车活动',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/activitycommon/newcar')
	},{
		path: '/market.activitycommon_newcaruser',
		name: 'market-activitycommon-newcaruser',
		meta: {
			title: '新车活动用户',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/activitycommon/newcaruser')
	},{
		path: '/market.activitycommon_discount',
		name: 'market-activitycommon-discount',
		meta: {
			title: '随机立减',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/activitycommon/discount')
	},{
		path: '/market.activitycommon_digit',
		name: 'market-activitycommon-digit',
		meta: {
			title: '数币活动',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/activitycommon/digit')
	},{
		path: '/market.activitycommon_coupon',
		name: 'market-activitycommon-coupon',
		meta: {
			title: '优惠券活动',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/activitycommon/coupon')
	},{
		path: '/market.yearbill_index',
		name: 'market-yearbill-index',
		meta: {
			title: '年度账单统计',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/yearbill')
	}, {
		path: '/market-actionPush',
		name: 'market-actionPush',
		meta: {
			title: '活动推广',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/actionPush')
	}, {
		path: '/adverts.adlist_index',
		name: 'market-ad-list',
		meta: {
			title: '广告位管理',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/ad')
	}, {
		path: '/market.zone_index',
		name: 'market-zone-list',
		meta: {
			title: '活动专区',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/zone')
	}, {
		path: '/market.jpush_index',
		name: 'market-jpush-list',
		meta: {
			title: '极光推送',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/jpush')
	}, {
		path: '/market-actionDiscount',
		name: 'market-actionDiscount',
		meta: {
			title: '卡券管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/actionDiscount')
	}, {
		path: '/market.qycard_index',
		name: 'market-qycard-list',
		meta: {
			title: '企业卡',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/qycard')
	}, {
		path: '/market.card_index',
		name: 'market-card-list',
		meta: {
			title: '电卡',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/market-management/card')
	}, {
		path: '/market.pack_index',
		name: 'market-pack-list',
		meta: {
			title: '券包配置',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/pack')
	}, {
		path: '/market.coupon_index',
		name: 'market-coupon-list',
		meta: {
			title: '优惠券',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/coupon')
	}, {
		path: '/energy-setting',
		name: 'energy-setting',
		meta: {
			title: '能量值管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/energy')
	}, {
		path: '/report-app-publish',
		name: 'report-app-publish',
		meta: {
			title: '站长推广',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/appPublish')
	}, {
		path: '/market-shop-index',
		name: 'market-shop-index',
		meta: {
			title: '叮当优选',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/market-management/shop')
	}]
}, {
	path: '/advertising-management',
	name: 'advertising-management',
	meta: {
		title: '广告管理',
		icon: 'el-icon-tickets',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/advertising-list',
		name: 'advertising-list',
		meta: {
			title: '广告信息',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/advertising-management/plan')
	}]
},{
	path: '/vip',
	name: 'vip',
	meta: {
		title: 'VIP管理',
		icon: 'el-icon-trophy',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/vip-gifts-index',
		name: 'vip-gifts-index',
		meta: {
			title: 'VIP套餐设置',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/vip/gifts-index')
	},{
		path: '/vip-vipzk-edit',
		name: 'vip-vipzk-edit',
		meta: {
			title: 'VIP等级配置',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/vip/vipzk-edit')
	},{
		path: '/vip-vipsq-edit',
		name: 'vip-vipsq-edit',
		meta: {
			title: 'VIP神券设置',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/vip/vipsq-edit')
	},{
		path: '/vip-vipxshd-edit',
		name: 'vip-vipxshd-edit',
		meta: {
			title: 'VIP限时活动',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/vip/vipxshd-edit')
	},{
		path: '/vip-vipyhcs-edit',
		name: 'vip-vipyhcs-edit',
		meta: {
			title: 'VIP其他参数',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/vip/vipyhcs-edit')
	},{
		path: '/vip-record-list',
		name: 'vip-record-list',
		meta: {
			title: 'VIP日志',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/vip/record')
	},{
		path: '/vip-box-lunch',
		name: 'vip-box-lunch',
		meta: {
			title: '会员权益1',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/vip/box-lunch')
	},{
		path: '/vip-box-log',
		name: 'vip-box-log',
		meta: {
			title: '会员权益1日志',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/vip/box-log')
	}]
}, {
	path: '/other-order',
	name: 'other-order',
	meta: {
		title: '其它订单',
		icon: 'el-icon-cpu',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/warehouse-list',
		name: 'warehouse-list',
		meta: {
			title: '农行订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/warehouse-management/warehouse')
	}, {
		path: '/warehouse-wareout',
		name: 'warehouse-wareout',
		meta: {
			title: '星星互联订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/warehouse-management/warehouse/xing')
	},{
		path: '/warehouse-privates',
		name: 'warehouse-privates',
		meta: {
			title: '私桩订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/warehouse-management/warehouse/privates')
	},{
		path: '/warehouse-warein',
		name: 'warehouse-warein',
		meta: {
			title: '入库信息',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/warehouse-management/warehouse/warein')
	}]
}/*, {
	path: '/worldcup',
	name: 'worldcup',
	meta: {
		title: '世界杯',
		icon: 'el-icon-football',
		hideInMenu: true,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/fifa-team-index',
		name: 'fifa-team-index',
		meta: {
			title: '队伍列表',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/worldcup/team-index')
	},{
		path: '/fifa-matchs-index',
		name: 'fifa-matchs-index',
		meta: {
			title: '赛事列表',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/worldcup/match-index')
	},{
		path: '/fifa-bet-detail',
		name: 'fifa-bet-detail',
		meta: {
			title: '竞猜明细',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/worldcup/bet-detail')
	},{
		path: '/fifa-bet-ranking',
		name: 'fifa-bet-ranking',
		meta: {
			title: '积分排行',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/worldcup/bet-bet')
	},{
		path: '/fifa-bet-summary',
		name: 'fifa-bet-summary',
		meta: {
			title: '数据统计',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/worldcup/bet-summary')
	}]
}*//*, {
	path: '/report-management',
	name: 'report-management',
	meta: {
		title: '报表管理',
		icon: 'el-icon-cpu',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: []
}*/, {
	path: '/data-analysis',
	name: 'data-analysis',
	meta: {
		title: '数据分析',
		icon: 'el-icon-data-analysis',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/data-analysis-group',
		name: 'data-analysis-group',
		meta: {
			title: '电站报表',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/report')
	},{
		path: '/user-fans-other',
		name: 'user-fans-other',
		meta: {
			title: '用户采集',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/fans/other')
	},{
		path: '/data-analysis-alarm',
		name: 'data-analysis-alarm',
		meta: {
			title: '用户报表',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/alarm')
	},{
		path: '/data-analysis-report',
		name: 'data-analysis-report',
		meta: {
			title: '营收统计',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/report2')
	},{
		path: '/report-group-list',
		name: 'report-group-list',
		meta: {
			title: '代理商明细表',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/index')
	},{
		path: '/report-group-lista',
		name: 'report-group-lista',
		meta: {
			title: '【A】小区消费查询',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/indexa')
	},{
		path: '/report-group-listb',
		name: 'report-group-listb',
		meta: {
			title: '【B】小区消费查询',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/indexb')
	}, {
		path: '/report-group-examine',
		name: 'report-group-examine',
		meta: {
			title: '小区考核查询',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/examine')
	}, {
		path: '/property-bill-management',
		name: 'property-bill-management',
		meta: {
			title: '物业结算管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/property-bill')
	}, {
		path: '/report-adverts-addetails',
		name: 'report-adverts-addetails',
		meta: {
			title: '广告明细表',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/addetails')
	}, {
		path: '/report-profit-list',
		name: 'report-profit-list',
		meta: {
			title: '站点利润',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/profit')
	}, {
		path: '/report-profit-statisticslist',
		name: 'report-profit-statisticslist',
		meta: {
			title: '利润统计',
			hideInMenu: true,
			closeTip: false,
			checkAccess: false
		},
		component: () => import('@/views/report-management/profitRecord')
	}]
}, {
	path: '/data-finance',
	name: 'data-finance',
	meta: {
		title: '财务管理',
		icon: 'el-icon-s-finance',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/report-group-finance',
		name: 'report-group-finance',
		meta: {
			title: '共建管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/finance')
	}, {
		path: '/report-group-settlelist',
		name: 'report-group-settlelist',
		meta: {
			title: '结算管理',
			hideInMenu: true,
			closeTip: false,
			checkAccess: false
		},
		component: () => import('@/views/report-management/group/settlelist')
	}, {
		path: '/report-group-settle',
		name: 'report-group-settle',
		meta: {
			title: '结算提现',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/report-management/group/settle')
	}, {
		path: '/report-group-settleview',
		name: 'report-group-settleview',
		meta: {
			title: '结算明细',
			hideInMenu: true,
			closeTip: false,
			checkAccess: false
		},
		component: () => import('@/views/report-management/group/settleview')
	}, {
		path: '/user-invoice-list',
		name: 'user-invoice-list',
		meta: {
			title: '开票管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/invoice')
	}, {
		path: '/user-refundexamine',
		name: 'user-refundexamine',
		meta: {
			title: '退款审核',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/user-management/refund/examine')
	}]
}, {
	path: '/hlht',
	name: 'hlht',
	meta: {
		title: '互联互通',
		icon: 'el-icon-link',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/hlht-list',
		name: 'hlht-list',
		meta: {
			title: '站点推送',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/hlht/index')
	}, {
		path: '/hlht-detail',
		name: 'hlht-detail',
		meta: {
			title: '站点推送详情',
			hideInMenu: true,
			closeTip: false
		},
		component: () => import('@/views/hlht/detail')
	}, {
		path: '/hlht-activity',
		name: 'hlht-activity',
		meta: {
			title: '活动折扣',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/hlht/activity')
	}, {
		path: '/agent-bill-management',
		name: 'agent-bill-management',
		meta: {
			title: '组织机构管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/agent-management/agent/property-bill')
	}, {
		path: '/hlht-order',
		name: 'hlht-order',
		meta: {
			title: '互联订单',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/hlht/order')
	}]
}, {
	path: '/system-management',
	name: 'system-management',
	meta: {
		title: '系统管理',
		icon: 'el-icon-setting',
		hideInMenu: false,
		isRoot: true
	},
	component: MainView,
	children: [{
		path: '/system-user-list',
		name: 'system-user-list',
		meta: {
			title: '账号管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/user')
	}, {
		path: '/system-role-list',
		name: 'system-role-list',
		meta: {
			title: '角色管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/role')
	}, {
		path: '/system-log-list',
		name: 'system-log-list',
		meta: {
			title: '日志管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/log')
	}, {
		path: '/system-send-log',
		name: 'system-send-log',
		meta: {
			title: '发放记录',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/send')
	}, {
		path: '/system-node-list',
		name: 'system-node-list',
		meta: {
			title: '节点管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/node')
	}, {
		path: '/system-attachment-list',
		name: 'system-attachment-list',
		meta: {
			title: '附件管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/attachment')
	}, {
		path: '/system-extension-list',
		name: 'system-extension-list',
		meta: {
			title: '推广人员',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/extension')
	},{
		path: '/system-appversion-index',
		name: 'system-appversion-index',
		meta: {
			title: 'APP版本管理',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/verupdate')
	},{
		path: '/system-config-function',
		name: 'system-config-function',
		meta: {
			title: '功能选择',
			hideInMenu: false,
			closeTip: false
		},
		component: () => import('@/views/system-management/function')
	},]
}]
