import config from '@/config'
import { forEach } from './tools'
import ar from 'element-ui/src/locale/lang/ar'

const { title } = config

/**
 * 根据当前跳转的路由设置显示在浏览器标签的title
 */
export const setTitle = (routeItem) => {
  const pageTitle = routeItem.meta.title
  const resTitle = config.title + '-' + pageTitle
  window.document.title = resTitle
}

/**
 * 通过路由列表得到菜单列表
 */
export const getMenuByRouter = (list) => {
  let res = []
  forEach(list, (item) => {
    if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
      let obj = {
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta: item.meta,
        path: item.path
      }
      if (hasChild(item)) {
        obj.children = getMenuByRouter(item.children)
      }
      res.push(obj)
    }
  })
  return res
}

/**
 * 判断是否有子节点
 * @param item
 * @returns {*|boolean}
 */
export const hasChild = (item) => {
  return item.children && item.children.length !== 0
}



/**
 * 获取所有路由
 */
export const getAllRouter = (list) => {
  let res = []
  for (let item of list) {
    res.push(item)
    if (hasChild(item)) {
      for (let child of item.children) {
        res.push(child)
      }
    }
  }
  return res
}

/**
 * 判断是否有权限
 */
export const hasAccess = (list, name, path) => {
  let lst = list.filter((item) => (item.name === "market-activity-action" || item.name === "market-actionPush" || item.name === "market-actionDiscount"));
  let ret = false;
  //console.log(list, lst);
  if(lst[0] && lst[0].actions) ret = lst[0].actions.some((item) => item === path.substring(1).replace('_', '/'));
  if(!ret){
    if(lst[1] && lst[1].actions) ret = lst[1].actions.some((item) => item === path.substring(1).replace('_', '/'));
  }
  if(!ret){
    if(lst[2] && lst[2].actions) ret = lst[2].actions.some((item) => item === path.substring(1).replace('_', '/'));
  }
  //console.log(lst[0].actions, ret, name, path);
  return ret || list.some((item) => item.name === name);
}

/**
 * 获取相同路由
 */
export const getSameRouters = (routeList, ruleList) => {
  let newArr = []
  for (let route of routeList) {
    if (route.meta.checkAccess === false) {
      newArr.push({
        icon: (route.meta && route.meta.icon) || '',
        name: route.name,
        meta: route.meta,
        path: route.path
      })
    }
    for (let rule of ruleList) {
      let newItem = null
      if (route.meta.title === rule.title) {
        newItem = {
          icon: (route.meta && route.meta.icon) || '',
          name: route.name,
          meta: route.meta,
          path: route.path
        }
        if (!route.meta.isRoot && rule.child && rule.child.length !== 0) {
          let actions = []
          for (let childItem of rule.child) {
            const index = childItem.name.indexOf('/');
            if(rule.title == '营销活动' || rule.title == '活动推广' || rule.title == '卡券管理'){
              actions.push(childItem.name);
            }else{
              actions.push(childItem.name.substring(index + 1))
            }
            
            //三级权限
            if(childItem.child){
              for(let subItem of childItem.child){
                actions.push(subItem.name)
              }
            }
          }
          newItem.actions = actions
        }
        if (!!rule.child && rule.child.length > 0) {
          if (!!route.children && route.children.length > 0) {
            newItem.children = getSameRouters(route.children, rule.child)
          }
        }
      }
      if (newItem) {
        newArr.push(newItem)
      }
    }
  }
  return newArr
}

/**
 * 拉平数组
 */
export const flatArray = (arr) => {
  return [].concat(...arr.map((item) => [].concat(item, ...flatArray(item.child))))
  // return treeToArray(arr)
}

export const treeToArray = (tree) => {
  let count = 0
  let newArr = []
  const expanded = (array) => {
    if (array && array.length > 0) {
      array.forEach((e) => {
        newArr.push(e)
        expanded(e.child)
      })
    }
  }
  expanded(tree)
  return newArr
}

/**
 *  本地存储和获取标签导航列表
 */
export const setTabListInLocalstorage = (list) => {
  localStorage.setItem(`${title}-navBarList`, JSON.stringify(list))
}

/**
 * @returns {Array} 其中的每个元素只包含路由原信息中的name, path, meta三项
 */
export const getTabListFromLocalstorage = () => {
  const list = localStorage.getItem(`${title}-navBarList`)
  return list ? JSON.parse(list) : []
}

export const setMenuListInLocalstorage = (list) => {
  localStorage.setItem(`${title}-menuList`, JSON.stringify(list))
}

export const getMenuListFromLocalstorage = () => {
  const list = localStorage.getItem(`${title}-menuList`)
  return list ? JSON.parse(list) : []
}

export const setAccessListInLocalstorage = (list) => {
  localStorage.setItem(`${title}-accessList`, JSON.stringify(list))
}

export const getAccessListFromLocalstorage = () => {
  const list = localStorage.getItem(`${title}-accessList`)
  return list ? JSON.parse(list) : []
}

/**
 * 存储现在的地址
 */
export const setCurrentPage = (page) => {
  localStorage.setItem(`${title}-currentPage`, page)
}

/**
 * 获取当前的地址
 */
export const getCurrentPage = () => {
  return localStorage.getItem(`${title}-currentPage`)
}

/**
 * 存储token
 */
export const setToken = (token) => {
  localStorage.setItem(`${title}-token`, token)
}

/**
 * 获取token
 */
export const getToken = () => {
  return localStorage.getItem(`${title}-token`)
}

/**
 * 存储用户信息
 */
export const setUserInfo = (userInfo) => {
  localStorage.setItem(`${title}-userInfo`, JSON.stringify(userInfo))
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  const userInfo = localStorage.getItem(`${title}-userInfo`)
  return userInfo ? JSON.parse(userInfo) : { route: [], realname: '' }
}

function formatNumber (n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export const formatTime = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  return (
    [year, month, day].map((item) => formatNumber(item)).join('-') +
    ' ' +
    [hour, minute, second].map((item) => formatNumber(item)).join(':')
  )
}

export const formatTime2 = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}年${month}月${day}日`
}

export const getHasChildNodeIdList = (nodes) => {
  let nodeIds = []
  for (let node of nodes) {
    if (node.child && node.child.length > 0) {
      nodeIds.push(node.node_id + '')
    }
  }
  return nodeIds
}

export const swapArr = (arr, index1, index2) => {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0]
  return arr
}

export function clearChild (arr) {
  if (arr.length === 0) {
    return []
  } else {
    for (let item of arr) {
      if (!!item.child && !item.child.length) {
        item.child = null
      } else {
        clearChild(item.child)
      }
    }
    return arr
  }
}

export const getFileIdList = (list) => {
  let fileIds = ''
  if (list.length === 0) {
    fileIds = ''
  } else if (list.length === 1) {
    fileIds = list[0].attach_id
  } else {
    for (let i = 0; i < list.length; i++) {
      if (i === 0) {
        fileIds = fileIds + list[i].attach_id
      } else {
        fileIds = fileIds + ',' + list[i].attach_id
      }
    }
  }
  return fileIds
}

/**
 * 深拷贝
 * @param target
 * @returns {any}
 */
export const deepClone = (target) => {
  return JSON.parse(JSON.stringify(target))
}

/**
 * 判断是否有相同的元素
 * @param list
 */
export function hasEqualList (data, ary) {
  let sameItem = null
  let tip = ''
  let nary = ary.slice().sort()
  for (let i = 0; i < ary.length; i++) {
    const item1 = nary[i]
    const item2 = nary[i + 1]
    if (
      !!item1 &&
      !!item2 &&
      item1.material_info_id === item2.material_info_id &&
      JSON.stringify(item1.param) == JSON.stringify(item2.param)
    ) {
      sameItem = nary[i]
      tip = `${data.class_name}存在两个相同的${item1.material_name}物料`
    }
  }
  return {
    sameItem: sameItem !== null,
    tip: tip
  }
}

/**
 * 根据id获取class
 * @param id
 * @param list
 */
export const getClassInfo = (id, list) => {
  for (let item of list) {
    if (id === item.class_id) {
      return item
    }
  }
}

/**
 * 百度坐标转高德
 */
export const bd2gd = (lng, lat) => {
  let x_pi = (3.14159265358979324 * 3000.0) / 180.0
  let x = lng - 0.0065
  let y = lat - 0.006
  let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
  let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
  let lngs = z * Math.cos(theta)
  let lats = z * Math.sin(theta)

  return {
    lng: lngs,
    lat: lats
  }
}

/**
 * 高德坐标转百度
 */
export const gd2bd = (lng, lat) => {
  let x_pi = (3.14159265358979324 * 3000.0) / 180.0
  let x = lng
  let y = lat
  let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi)
  let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi)
  let lngs = z * Math.cos(theta) + 0.0065
  let lats = z * Math.sin(theta) + 0.006

  return {
    lng: lngs,
    lat: lats
  }
}
