const Common = {
  /**
   * 秒转时间
   */
  secondToTime (second) {
    let sec = 0
    let min = 0
    let hour = 0
    if (second > 60) {
      min = parseInt(second / 60)
      sec = parseInt(second % 60)
      if (min > 60) {
        hour = parseInt(min / 60)
        min = parseInt(min % 60)
      }
    }
    return this.intToDd(hour) + ':' + this.intToDd(min) + ':' + this.intToDd(sec)
  },
  /**
   * 转双位
   */
  intToDd (int) {
    if (int < 10) {
      return '0' + int
    }
    return int
  },
  /**
   * 时间戳转时间
   */
  stampToDatetime (stamp) {
    const date = new Date(stamp)
    return this.intToDd(date.getFullYear()) + '-' + this.intToDd(date.getMonth() + 1) + '-' + this.intToDd(date.getDate()) + ' ' + this.intToDd(date.getHours()) + ':' + this.intToDd(date.getMinutes()) + ':' + this.intToDd(date.getSeconds())
  },
  /** 日期格式话 */
  DateTimeStr(val){
    let dt = new Date();
    if(val) dt = new Date(val);
    let str = dt.getFullYear() + "-";
    if(dt.getMonth() > 8){
      str += (dt.getMonth()+1) + "-";
    }else{
      str += "0" + (dt.getMonth()+1) + "-";
    }
    if(dt.getDate() > 9){
      str += dt.getDate() + " ";
    }else{
      str += "0" + (dt.getDate()+1) + " ";
    }
    if(dt.getHours() > 9){
      str += dt.getHours() + ":";
    }else{
      str += "0" + dt.getHours() + ":";
    }
    if(dt.getMinutes() > 9){
      str += dt.getMinutes() + ":";
    }else{
      str += "0" + dt.getMinutes() + ":";
    }
    if(dt.getSeconds() > 9){
      str += dt.getSeconds();
    }else{
      str += "0" + dt.getSeconds();
    }
    return str;
  }
}

export default Common
