import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './theme/index.css'
import 'xe-utils'
import VXETable from 'vxe-table'
// import 'vxe-table/lib/index.css'
import './theme/style.scss'
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import BaiduMap from 'vue-baidu-map'
import API from '@/service/api.js'
import Common from '@/utils/common.js'
import ECharts from "echarts"
import VueECharts from "vue-echarts"

Vue.use(ElementUI)
Vue.use(VXETable)
VXETable.use(VXETablePluginElement)

Vue.component(VueQrcode.name, VueQrcode)
Vue.component("v-chart", VueECharts)

Vue.config.productionTip = false

Vue.prototype.$api = API

Vue.prototype.$common = Common;

Vue.prototype.$hasAccess = (val) => {
	return store.state.user.userInfo.route.includes(val)
}
//用户来源
Vue.prototype.$userArea = {
	'20':'苏州市',
	'30':'无锡市',
	'40':'南通市',
	'50':'泰州市',
	'60':'上海市',
};
//用户类型
Vue.prototype.$userType = {
	'1':'VIP',
	'2':'非VIP',
};
//设备类型 枪口类型
Vue.prototype.$deviceType = {
	'10':'慢充',
	'20':'快充',
};
//支付类型
Vue.prototype.$payType = {
	10: '钱包',
	20: '在线卡',
	21: '在线卡',
	30: '先付后充',
//	40: '赠送金',
	50: '信用分',
	60: '企业卡',
	61: '个人电卡',
	62: '数币支付',
	70: '芝麻先享'
};
//支付来源
Vue.prototype.$paySource = {
	10:"微信小程序",
	20:"支付宝小程序",
	50:"APP",
	60:"APP",
	62:"数币支付",
};
//支付来源
Vue.prototype.$pay_source = {
	1:"微信小程序",
	3:"支付宝小程序",
	2:"APP",
};
//vip卡类型
Vue.prototype.$vipType = {
	30: '月卡',
	90: '季卡',
	180: '半年卡',
	360: '年卡'
};
//平台来源
Vue.prototype.$platform = {
	0:"全部",
	1:"APP",
	2:"小程序",
};
Vue.use(BaiduMap, {
	// ak: 'f6zHImwwyyHaOKnhIO7pkMjFuHITIk5o'
	ak: 'PNdtzWCGBvPDr3nsMc8PuQ4gKxpikACR'
})

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app')
