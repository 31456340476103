import { asyncRoutes } from '@/router/routes'

import {
  getMenuByRouter,
  setTabListInLocalstorage,
  getTabListFromLocalstorage
} from '@/utils/util'
import { deepClone } from '../../utils/util'
import API from '@/service/api'

export default {
  state: {
    allRules: [],
    tabList: [], // tab标签页
    sideFlag: false
  },
  mutations: {
    setTabList (state, list) {
      let theTabList = []
      if (list) {
        theTabList = [...list]
      } else {
        theTabList = getTabListFromLocalstorage()
      }
      state.tabList = theTabList
      setTabListInLocalstorage(state.tabList)
    },
    addTab (state, route) {
      let thisTabList = deepClone(state.tabList)
      let hasExist = false
      for (let i = 0; i < thisTabList.length; i++) {
        if (thisTabList[i].name === route.name) {
          thisTabList[i] = route
          hasExist = true
        }
      }
      if (!hasExist) {
        thisTabList.push(route)
      }
      state.tabList = thisTabList
      setTabListInLocalstorage(state.tabList)
    },
    showSide (state) {
      state.sideFlag = true
    },
    hideSide (state) {
      state.sideFlag = false
    }
  },
  actions: {

  }
}
