import axios from 'axios'
import qs from 'qs'
import config from '@/config'
import store from '@/store'
import router from '@/router'
import { Notification } from 'element-ui'

const BaseUrl = config.baseUrl

const Request = {
  get: (url, params) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: BaseUrl + url,
        params: params || ''
      }).then(res => {
        if (res.status === 200 && !!res.data) {
          if (res.data.code === 0) {
            resolve(res.data.data)
          } else if (res.data.code === 500) {
            Notification.error({
              title: '错误',
              message: res.data.msg
            })
            reject(res.data)
          } else if (res.data.code === 1000) {
            router.push({
              name: 'page-1000'
            })
            reject(0)
          } else if (res.data.code === 1001) {
            router.push({
              name: 'page-1001'
            })
            reject()
          } else if (res.data.code === 1002) {
            router.push({
              name: 'page-1002'
            })
            reject()
          }
        } else {
          Notification.error({
            title: '错误',
            message: res.data.msg
          })
          reject(res.data)
        }
      }).catch(err => {
        Notification.error({
          title: '错误',
          message: err
        })
        reject(err)
      })
    })
  },

  post: (url, data, auth) => {
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
    if (auth) {
      headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'auth': 'false'
      }
    }
    if(!("token" in data)){
      data.token = store.state.user.token
    }
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: BaseUrl + url,
        headers: headers,
        data: qs.stringify(data)
      }).then(res => {
        if (res.status === 200 && !!res.data) {
          if (res.data.code === 0) {
            resolve(res.data.data)
          } else if (res.data.code === 500) {
            Notification.error({
              title: '错误',
              message: res.data.msg
            })
            reject(res.data)
          } else if (res.data.code === 1000) {
            router.push({
              name: 'page-1000'
            })
            reject(0)
          } else if (res.data.code === 1001) {
            router.push({
              name: 'page-1001'
            })
            reject()
          } else if (res.data.code === 1002) {
            router.push({
              name: 'page-1002'
            })
            reject()
          } else {
            reject(res.data)
          }
        } else {
          Notification.error({
            title: '错误',
            message: res
          })
          reject(res.data)
        }
      }).catch(err => {
        Notification.error({
          title: '错误',
          message: err
        })
        reject(err)
      })
    })
  }
}

export default Request
