import Vue from 'vue'
import VueRouter from 'vue-router'
import { currencyRoutes, asyncRoutes } from './routes'
import store from '@/store'
import { setTitle, getSameRouters, getAllRouter, hasAccess } from '@/utils/util'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [...currencyRoutes, ...asyncRoutes]
})

router.beforeEach((to, from, next) => {
  const token = store.state.user.token
  // 页面不存在
  if (!!to.meta.isRoot || to.matched.length === 0) {
    next({ replace: true, name: 'page-404' })
  } else {
    // 如果未登录，且跳转的页面不是登录页，跳转到登录页
    if (!token && to.name !== 'login') {
      next({ name: 'login' })
    }
    // 如果未登录，且跳转的页面是登录页，正常跳转
    else if (!token && to.name === 'login') {
      next()
    }
    // 已登录且要跳转的页面是登录页
    else if (!!token && to.name === 'login') {
      next({ name: 'home' })
    }
    // 已登录且要跳转的页面不是登录页
    else {
      if (store.state.user.userRouters.length === 0 || store.state.user.menuRouters === 0) {
        const menuRouters = getSameRouters(asyncRoutes, store.state.user.userInfo.rule)
        const userRouters = getAllRouter([
          ...currencyRoutes,
          ...getSameRouters(asyncRoutes, store.state.user.userInfo.rule)
        ])
        store.commit('setMenuRouters', menuRouters);
        store.commit('setUserRouters', userRouters);
        if (hasAccess(userRouters, to.name, to.path)) {
          next()
        } else {
          next({ name: 'page-403' })
        }
      } else {
        if (hasAccess(store.state.user.userRouters, to.name, to.path)) {
          next()
        } else {
          next({ name: 'page-403' })
        }
      }
    }
  }
})

router.afterEach((to) => {
  setTitle(to)
  window.scrollTo(0, 0)
})

export default router
