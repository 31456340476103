import {
  setToken,
  getToken,
  setUserInfo,
  getUserInfo
} from '@/utils/util'

import API from '@/service/api'
import store from '@/store'

export default {
  state: {
    token: getToken(),
    userInfo: getUserInfo(),
    userRouters: [],
    menuRouters: [],
    ruleRouters: []
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      setToken(token)
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
      setUserInfo(userInfo)
    },
    setUserRouters (state, routers) {
      state.userRouters = routers
    },
    setMenuRouters (state, routers) {
      state.menuRouters = routers
    },
    setRuleRouters (state, routers) {
      state.ruleRouters = routers
    }
  },
  actions: {
    login ({ commit, state }, { username, password }) {
      return new Promise((resolve, reject) => {
        API.User.Login({ username, password }).then(res => {
          if (state.userInfo != '' && state.userInfo.username === res.user.username) {
            // 保存token
            commit('setToken', res.Token)
            // 保存用户信息
            commit('setUserInfo', res.user)

            resolve('old')
          } else {
            // 保存token
            commit('setToken', res.Token)
            // 保存用户信息
            commit('setUserInfo', res.user)

            resolve('new')
          }
        }).catch(() => {
          reject('用户名或密码错误！')
        })
      })
    },
    logOut ({ commit }) {
      return new Promise((resolve, reject) => {
        const params = {
          token: store.state.user.token
        }
        API.User.LogOut(params).then(() => {
          commit('setToken', '')
          commit('setUserRouters', [])
          commit('setMenuRouters', [])
          commit('setRuleRouters', [])
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
    clearCache ({ commit }) {
      commit('setToken', '')
      commit('setUserRouters', [])
      commit('setMenuRouters', [])
      commit('setRuleRouters', [])
    }
  }
}
