<template>
  <div class="layout">
    <el-container>
      <Side></Side>
      <el-container class="container-main">
        <hHeader></hHeader>
        <NavTab></NavTab>
        <el-main id="main">
          <transition
            name="main"
            mode="out-in"
          >
            <keep-alive :include="cacheList">
              <router-view></router-view>
            </keep-alive>
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Side from './side'
import hHeader from './h-header'
import NavTab from './nav-tab'

export default {
  name: 'main-view',
  components: {
    Side,
    hHeader,
    NavTab
  },
  computed: {
    cacheList () {
      // 注意是组件的名字(name)，不是路由的名字
      return this.$store.state.app.tabList.map(item => item.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
}

.el-container {
  height: 100%;
}

.container-main {
  display: flex;
  flex-direction: column;
}

#main {
  background: #f6f7f9;
  padding: 10px 20px;
  height: 100%;
}
</style>

<style lang="scss" scoped>
// 小屏幕响应
@media screen and (max-width: 767px) {
}
</style>
