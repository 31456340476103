const href = window.location.href
let BaseUrl = ''

if (href.includes('yz.show.jsyzcd.cn')) {
	// 生产
	BaseUrl = 'https://yz.api.jsyzcd.cn/'
} else if (href.includes('qc.cloud.7wango.cn')) {
	// 测试
	BaseUrl = 'https://qc.api.7wango.cn/'
} else if (href.includes('zj.show.7wango.cn')) {
	// 镇江
	BaseUrl = 'https://zjcd.7wango.cn/'
} else if (href.includes('yz.show.xiaolucd.com')) {
	// 慢充服务器
	BaseUrl = 'https://yz.api.xiaolucd.com/'
} else if (href.includes('yycd.show.7wango.cn')) {
	// 元元充电
	BaseUrl = 'https://yycd.7wango.cn/'
} else if (href.includes('hzd.show.7wango.cn')) {
	// 恒致达
	BaseUrl = 'https://hzd.api.7wango.cn/'
} else if (href.includes('sqc.show.suqichong.cn')) {
	// 恒致达
	BaseUrl = 'https://sqc.api.suqichong.cn/'
} else if (href.includes('lh.show.xiaolucd.com')) {
	// 南京利禾
	BaseUrl = 'https://lh.api.xiaolucd.com/'
} else {
	// 本地
	BaseUrl = 'http://yz.hl.jsyzcd.cn/'
	// BaseUrl = 'http://192.168.2.101:8006/'
	if(process.env.VUE_APP_API_BASE_URL) BaseUrl = process.env.VUE_APP_API_BASE_URL;
}
export default {
	/**
	 * @description 版本号
	 */
	version: '0.6.8',
	/**
	 * @description 项目名称
	 */
	title: '叮当充电',
	/*
	 * @description api请求基础路径
	 */
	baseUrl: BaseUrl
	/**
	 * @description 默认打开的首页的路由name值，默认为home
	 */
}
