<template>
  <el-header class="header">
    <i class="menuicon el-icon-menu" @click="showSide"></i>
    <div class="header-left">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="item in breadcrumbList">{{item.meta.title}}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="header-right">
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <span
            v-if="userInfo"
            class="user-name"
          >{{userInfo.realname}} - {{userInfo.role.role_name}}<i class="el-icon-caret-bottom"></i></span>
          <el-image
            class="user-avatar"
            :src="userSrc"
          ></el-image>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-switch-button"
            command="logOut"
          >退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script>
import userSrc from '@/assets/user.jpg'
import API from '@/service/api'
import { mapActions } from 'vuex'

export default {
  name: 'h-header',
  data () {
    return {
      userSrc
    }
  },
  computed: {
    breadcrumbList () {
      return this.$route.matched.filter(item => item.meta && item.meta.title)
    },
    userInfo () {
      if (this.$store.state.user.userInfo) {
        return this.$store.state.user.userInfo
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions([
      'logOut'
    ]),
    handleCommand (command) {
      if (command === 'logOut') {
        this.$confirm('确定要退出登录吗', '提示', {
          cancelButtonClass: 'btn-custom-cancel',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.logOut().then(() => {
            this.$router.push({
              name: 'login'
            })
          })
        })
      }
    },
    showSide () {
      this.$store.commit('showSide')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 6px rgba(0, 21, 41, 0.08);

  &-left {
  }

  &-right {
    display: flex;
    align-items: center;
  }
}

.el-dropdown-link {
  display: flex;
  align-items: center;
  cursor: pointer;

  .user-name {
    font-size: 14px;
    margin-right: 10px;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
}
.menuicon {
  display: none;
}
</style>

<style lang="scss" scoped>
// 小屏幕响应
@media screen and (max-width: 767px) {
  .user-name {
    display: none;
  }
  .header-left {
    flex-grow: 1;
  }
  .header-right {
    flex-shrink: 0;
  }
  .el-breadcrumb {
    line-height: 1.4;
    margin-right: 20px;
  }
  .menuicon {
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 15px;
    color: #666;
  }
}
</style>
