<template>
  <div class="nav-bar">
    <el-tabs
      class="tabs"
      v-model="activeTab"
      type="card"
      closable
      tab-position="top"
      @tab-remove="closeTab"
      @tab-click="clickTab"
      :before-leave="beforeLeaveTab"
    >
      <el-tab-pane
        v-for="item in tabList"
        :key="item.name"
        :label="item.meta.title"
        :name="item.name"
      >
      </el-tab-pane>
    </el-tabs>
    <div class="close">
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <i class="el-icon-arrow-down close-main"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-close"
            command="other"
          >关闭其他</el-dropdown-item>
          <el-dropdown-item
            icon="el-icon-error"
            command="all"
          >关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'nav-bar',
  data () {
    return {
      activeTab: ''
    }
  },
  computed: {
    // activeTab: {
    //     get() {
    //         return this.$route.name
    //     },
    //     set(val) {
    //
    //     }
    // },
    tabList () {
      return this.$store.state.app.tabList
    }
  },
  watch: {
    '$route' (newRoute) {
      const { name, query, params, meta } = newRoute
      this.activeTab = name
      if (name !== 'home') {
        this.addTab({ name, query, params, meta })
      }
    }
  },
  mounted () {
    // 初始化标签页
    this.setTabList()

    this.activeTab = this.$route.name
  },
  methods: {
    ...mapMutations([
      'setTabList',
      'addTab'
    ]),
    beforeLeaveTab (activeName, oldActiveName) {
      // 判断老的路由和现在的路由
      if (oldActiveName === this.$route.name) {
        return false
      } else {
        return true
      }
    },
    clickTab (tab) {
      for (let item of this.tabList) {
        if (item.name === tab.name) {
          this.$router.push({
            name: item.name,
            query: item.query
          })
          return
        }
      }
    },
    closeTab (targetName) {
      let tabs = this.tabList
      let thisTab = null
      let activeName = this.activeTab
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.name
            }
          }
        })
      }
      tabs.forEach((tab, index) => {
        if (tab.name === targetName) {
          thisTab = tab
        }
      })
      // if (thisTab.meta.closeTip) {
      //     this.$confirm('确定要关闭当前页面吗', '提示', {
      //         cancelButtonClass: 'btn-custom-cancel',
      //         confirmButtonText: '确定',
      //         cancelButtonText: '取消',
      //         type: 'warning'
      //     }).then(() => {
      //         this.handleCloseTab(tabs, targetName, activeName)
      //     })
      // } else {
      this.handleCloseTab(tabs, targetName, activeName)
      // }
    },
    handleCloseTab (tabs, targetName, activeName) {
      this.setTabList(tabs.filter(tab => tab.name !== targetName))
      if (this.tabList.length === 0) {
        this.$router.push({
          name: 'home'
        })
      } else {
        for (let item of this.tabList) {
          if (item.name === activeName) {
            this.$router.push({
              name: item.name,
              query: item.query
            })
            return
          }
        }
      }
    },
    handleCommand (command) {
      if (command === 'all') {
        this.$confirm('确定要关闭所有标签吗', '提示', {
          cancelButtonClass: 'btn-custom-cancel',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            name: 'home'
          })
          this.setTabList([])
        })
      } else if (command === 'other') {
        this.$confirm('确定要关闭其他标签吗', '提示', {
          cancelButtonClass: 'btn-custom-cancel',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const thisRouter = this.$route
          if (thisRouter.name !== 'home') {
            this.setTabList([])
            const { name, query, params, meta } = thisRouter
            this.addTab({ name, query, params, meta })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.nav-bar {
  .el-tabs--card > .el-tabs__header {
    height: 30px;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none !important;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border: none !important;
    margin: 0 3px;
  }

  .el-tabs--card > .el-tabs__header {
    border: none !important;
    margin: 0 50px 0 0;
  }

  .el-tabs__item {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 12px !important;
    color: #616266 !important;
    background: #ffffff !important;
  }

  .el-tabs__item.is-active {
    color: #008e4d !important;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item {
    /*border: 1px solid #E4E7ED !important;*/
  }

  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    line-height: 30px !important;
  }

  .el-dropdown-menu {
    padding: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.nav-bar {
  position: relative;
  z-index: 8;
  background: #f6f7f9;
}

.tabs {
  padding: 10px;
}

.close {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border: 1px solid #e4e7ed !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.close-main {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
