import Request from './request'
const API = {
	Config: {},
	User: {
		Login: ({
			username,
			password
		}) => {
			return Request.post('api/passport/login', {
				username,
				password
			})
		},
		LogOut: ({
			token
		}) => {
			return Request.post('api/passport/logout', {
				token
			})
		}
	},
	System: {
		GetLogList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/system.log/index', Object.assign({
				token,
				page,
				size
			}, searchParams), 'auth')
		},
		GetLogDetail: ({
			token,
			log_id
		}) => {
			return Request.post('api/system.log/view', {
				token,
				log_id
			}, 'auth')
		},
		GetSendLog: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/erp.log/index', Object.assign({
				token,
				page,
				size
			}, searchParams), 'auth')
		},
		GetUserList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/system.user/index', Object.assign({
				token,
				page,
				size
			}, searchParams), 'auth')
		},
		GetUserDetail: ({
			token,
			user_id
		}) => {
			return Request.post('api/system.user/view', {
				token,
				user_id
			}, 'auth')
		},
		AddUser: ({
			token,
			form
		}) => {
			return Request.post('api/system.user/add', {
				token,
				form
			})
		},
		EditUser: ({
			token,
			form
		}) => {
			return Request.post('api/system.user/edit', {
				token,
				form
			})
		},
		DeleteUser: ({
			token,
			user_id
		}) => {
			return Request.post('api/system.user/delete', {
				token,
				user_id
			})
		},
		GetRoleList: ({
			token,
			page,
			size
		}) => {
			return Request.post('api/system.role/index', Object.assign({
				token,
				page,
				size
			}), 'auth')
		},
		GetRoleDetail: ({
			token,
			role_id
		}) => {
			return Request.post('api/system.role/view', {
				token,
				role_id
			}, 'auth')
		},
		AddRole: ({
			token,
			form
		}) => {
			return Request.post('api/system.role/add', {
				token,
				form
			})
		},
		EditRole: ({
			token,
			form
		}) => {
			return Request.post('api/system.role/edit', {
				token,
				form
			})
		},
		DeleteRole: ({
			token,
			role_id
		}) => {
			return Request.post('api/system.role/delete', {
				token,
				role_id
			})
		},
		GetNodeList: ({
			token,
			page,
			size
		}) => {
			return Request.post('api/system.node/index', {
				token,
				page,
				size
			}, 'auth')
		},
		GetNodeDetail: ({
			token,
			node_id
		}) => {
			return Request.post('api/system.node/view', {
				token,
				node_id
			}, 'auth')
		},
		AddNode: ({
			token,
			form
		}) => {
			return Request.post('api/system.node/add', {
				token,
				form
			})
		},
		EditNode: ({
			token,
			form
		}) => {
			return Request.post('api/system.node/edit', {
				token,
				form
			})
		},
		DeleteNode: ({
			token,
			node_id
		}) => {
			return Request.post('api/system.node/delete', {
				token,
				node_id
			})
		},
		GetFileList: ({
			token,
			page,
			size,
			pj_id
		}) => {
			return Request.post('api/system.attachment/index', {
				token,
				page,
				size,
				pj_id
			}, 'auth')
		},
		GetExtensionList: ({
			token,
			page,
			size
		}) => {
			return Request.post('api/system.extension/index', Object.assign({
				token,
				page,
				size
			}), 'auth')
		},
		AddExtension: ({
			token,
			form
		}) => {
			return Request.post('api/system.extension/add', {
				token,
				form
			})
		},
		DeleteExtension: ({
			token,
			id
		}) => {
			return Request.post('api/system.extension/delete', {
				token,
				id
			})
		},
		VerupdateIndex: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('/api/system/verupdate/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		VerupdateAdd: (data) => {
			return Request.post('/api/system/verupdate/add', data)
		},
		VerupdateEdit: (data) => {
			return Request.post('/api/system/verupdate/edit', data)
		},
		VerupdateDel: (data) => {
			return Request.post('/api/system/verupdate/del', data)
		},
		UserMinList: (data) => {
			return Request.post('/api/system/user/minlist', data)
		},
		Config:{ //通用配置
			detail: (data)=>{
				return Request.post('/api/system.config/detail', data)
			},
			edit: (data)=>{
				return Request.post('/api/system.config/edit', data)
			}
		}
	},
	Device: {
		// 设备
		GetDeviceList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.device/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 获取代理商设备
		GetDeviceAgentList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.device/deviceagent', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 获取后台用户
		GetAdminUserList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.device/adminuser', Object.assign({
				token,
				page,
				size
			}, searchParams))
		}, // 标签列表
		GetLabelList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.label/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		LabelEdit: (data) => {
			return Request.post('api/device.label/edit', data)
		},
		AddDevice: ({
			token,
			form
		}) => {
			return Request.post('api/device.device/add', {
				token,
				form
			})
		},
		GetDeviceDetail: ({
			token,
			device_id
		}) => {
			return Request.post('api/device.device/view', {
				token,
				device_id
			})
		},
		UnusedTwoPlugs: (data) => {
			return Request.post('api/device.plug/unused_two_plugs', data);
		},
		EditDevice: ({
			token,
			form
		}) => {
			return Request.post('api/device.device/edit', {
				token,
				form
			})
		},
		DeleteDevice: ({
			token,
			device_id
		}) => {
			return Request.post('api/device.device/delete', {
				token,
				device_id
			})
		},
		QrCodeDevice: (data) => {
			return Request.post('api/device.device/qrcode', data)
		},
		// 交互日志
		GetWorkOrder: ({
			token,
			page,
			size,
			device_num, agree, qk_type
		}, searchParams) => {
			return Request.post('api/device.device/log', Object.assign({
				token,
				page,
				size,
				device_num, agree, qk_type
			}, searchParams))
		},
		// 设备类型
		GetTypeList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.type/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		AddType: ({
			token,
			form
		}) => {
			return Request.post('api/device.type/add', {
				token,
				form
			})
		},
		GetTypeDetail: ({
			token,
			type_id
		}) => {
			return Request.post('api/device.type/view', {
				token,
				type_id
			})
		},
		EditType: ({
			token,
			form
		}) => {
			return Request.post('api/device.type/edit', {
				token,
				form
			})
		},
		DeleteType: ({
			token,
			type_id
		}) => {
			return Request.post('api/device.type/delete', {
				token,
				type_id
			})
		},
		// 插座
		GetPlugList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.plug/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		AddPlug: ({
			token,
			form
		}) => {
			return Request.post('api/device.plug/add', {
				token,
				form
			});
		},
		BatchAddPlug: ({
			token,
			form
		}) => {
			return Request.post('api/device.plug/batch_add', {
				token,
				form
			});
		},
		GetPlugDetail: ({
			token,
			plug_id
		}) => {
			return Request.post('api/device.plug/view', {
				token,
				plug_id
			})
		},
		EditPlug: ({
			token,
			form
		}) => {
			return Request.post('api/device.plug/edit', {
				token,
				form
			})
		},
		DeletePlug: ({
			token,
			plug_id
		}) => {
			return Request.post('api/device.plug/delete', {
				token,
				plug_id
			})
		},
		// 小区
		GetGroupList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.group/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 小区简单
		GetGroupMinList: (params) => {
			return Request.post('api/device.group/minlist', params)
		},
		GroupDeviceNo: (params) => { //设备ID编号列表
			return Request.post('api/device.group/device_nos', params)
		},
		managerList:(params)=>{ //erp管理员列表
			return Request.post('api/device.group/managerlist', params)
		},
		// 用户
		GetUserList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/system.user/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 小区
		GetSeatList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.seat/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		AddGroup: ({
			token,
			form
		}) => {
			return Request.post('api/device.group/add', {
				token,
				form
			})
		},
		AddSeat: ({
			token,
			form
		}) => {
			return Request.post('api/device.seat/add', {
				token,
				form
			})
		},
		GetGroupDetail: ({
			token,
			group_id
		}) => {
			return Request.post('api/device.group/view', {
				token,
				group_id
			})
		},
		GetSeatDetail: ({
			token,
			id
		}) => {
			return Request.post('api/device.seat/view', {
				token,
				id
			})
		},
		EditGroup: ({
			token,
			form
		}) => {
			return Request.post('api/device.group/edit', {
				token,
				form
			})
		},
		EditSeat: ({
			token,
			form
		}) => {
			return Request.post('api/device.seat/edit', {
				token,
				form
			})
		},
		DeleteGroup: ({
			token,
			group_id
		}) => {
			return Request.post('api/device.group/delete', {
				token,
				group_id
			})
		},
		UpstatusSeat: ({
			token,
			id,
			status
		}) => {
			return Request.post('api/device.seat/upstatus', {
				token,
				id,
				status
			})
		},
		SyncGroup: ({
			token,
			group_id
		}) => {
			return Request.post('api/device.group/update', {
				token,
				group_id
			})
		},
		// 报修
		GetRepaireList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/device.repaire/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		AddRepaire: ({
			token,
			form
		}) => {
			return Request.post('api/device.repaire/add', {
				token,
				form
			})
		},
		EditRepaire: ({
			token,
			form
		}) => {
			return Request.post('api/device.repaire/edit', {
				token,
				form
			})
		},
		plugStatusLog: (params) => {
			return Request.post('api/device.device/plug_status_log', params);
		},
	},
	Market: {
		// 充值优惠列表
		GetPlanList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.plan/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 充值优惠详情
		GetPlanDetail: ({
			token,
			plan_id
		}) => {
			return Request.post('api/market.plan/view', {
				token,
				plan_id
			})
		},
		// 添加充值优惠
		AddPlan: ({
			token,
			form
		}) => {
			return Request.post('api/market.plan/add', {
				token,
				form
			})
		},
		// 编辑充值优惠
		EditPlan: ({
			token,
			form
		}) => {
			return Request.post('api/market.plan/edit', {
				token,
				form
			})
		},
		// 删除充值优惠
		DeletePlan: ({
			token,
			plan_id
		}) => {
			return Request.post('api/market.plan/del', {
				token,
				plan_id
			})
		},
		// 优惠券列表
		GetCouponList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.coupon/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 电卡列表
		GetCardList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.card/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 优惠券列表
		GetCouponUrlList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.coupon/urlindex', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 充值优惠详情
		GetCouponDetail: ({
			token,
			coupon_id
		}) => {
			return Request.post('api/market.coupon/view', {
				token,
				coupon_id
			})
		},
		// 添加充值优惠
		AddCoupon: ({
			token,
			form
		}) => {
			return Request.post('api/market.coupon/add', {
				token,
				form
			})
		},
		// 电卡详情
		GetCardDetail: ({
			token,
			card_id
		}) => {
			return Request.post('api/market.card/view', {
				token,
				card_id
			})
		},
		// 添加电卡
		AddCard: ({
			token,
			form
		}) => {
			return Request.post('api/market.card/add', {
				token,
				form
			})
		},
		// 电卡记录
		CardRecord: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.card/record', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 添加充值优惠
		AddCouponUrl: ({
			token,
			form
		}) => {
			return Request.post('api/market.coupon/saveurl', {
				token,
				form
			})
		},
		// 编辑充值优惠
		EditCoupon: ({
			token,
			form
		}) => {
			return Request.post('api/market.coupon/edit', {
				token,
				form
			})
		},
		// 编辑电卡
		EditCard: ({
			token,
			form
		}) => {
			return Request.post('api/market.card/edit', {
				token,
				form
			})
		},
		// 优惠券列表
		GetActivityList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.activity/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 充值优惠详情
		GetActivityDetail: ({
			token,
			activity_id
		}) => {
			return Request.post('api/market.activity/view', {
				token,
				activity_id
			})
		},
		// 添加充值优惠
		AddActivity: ({
			token,
			form
		}) => {
			return Request.post('api/market.activity/add', {
				token,
				form
			})
		},
		// 编辑充值优惠
		EditActivity: ({
			token,
			form
		}) => {
			return Request.post('api/market.activity/edit', {
				token,
				form
			})
		},
		// 发放代金券
		OutCoupon: ({
			token,
			form
		}) => {
			return Request.post('api/market.coupon/editout', {
				token,
				form
			})
		},
		// 发放电卡
		OutCard: ({
			token,
			form
		}) => {
			return Request.post('api/market.card/editout', {
				token,
				form
			})
		},
		// 删除充值优惠
		DeleteCoupon: ({
			token,
			plan_id
		}) => {
			return Request.post('api/market.coupon/del', {
				token,
				plan_id
			})
		},
		// 删除充值优惠
		DeleteCard: ({
			token,
			card_id
		}) => {
			return Request.post('api/market.card/del', {
				token,
				card_id
			})
		},
		// 删除充值优惠
		DeleteCouponUrl: ({
			token,
			id
		}) => {
			return Request.post('api/market.coupon/delurl', {
				token,
				id
			})
		},
		// 删除充值优惠
		DeleteActivity: ({
			token,
			activity_id
		}) => {
			return Request.post('api/market.activity/del', {
				token,
				activity_id
			})
		},
		// 充电优惠列表
		GetGiftList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.gift/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 充电优惠详情
		GetGiftDetail: ({
			token,
			gift_id
		}) => {
			return Request.post('api/market.gift/view', {
				token,
				gift_id
			})
		},
		// 添加充电优惠
		AddGift: ({
			token,
			form
		}) => {
			return Request.post('api/market.gift/add', {
				token,
				form
			})
		},
		// 编辑充电优惠
		EditGift: ({
			token,
			form
		}) => {
			return Request.post('api/market.gift/edit', {
				token,
				form
			})
		},
		// 删除充电优惠
		DeleteGift: ({
			token,
			gift_id
		}) => {
			return Request.post('api/market.gift/del', {
				token,
				gift_id
			})
		},
		// 添加充值优惠
		AddQy: ({
			token,
			form
		}) => {
			return Request.post('api/market.qycard/add', {
				token,
				form
			})
		},
		// 优惠券列表
		GetQycardList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.qycard/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 删除企业
		DeleteQy: ({
			token,
			id
		}) => {
			return Request.post('api/market.qycard/del', {
				token,
				id
			})
		},
		// 删除人员
		DeleteMem: ({
			token,
			id
		}) => {
			return Request.post('api/market.qycard/delmem', {
				token,
				id
			})
		},
		// 充值
		QyRecharge: ({
			token,
			form
		}) => {
			return Request.post('api/market.qycard/qyrecharge', {
				token,
				form
			})
		},
		// 分配电量
		Qyoutele: ({
			token,
			form
		}) => {
			return Request.post('api/market.qycard/outele', {
				token,
				form
			})
		},
		// 添加成员
		QyMem: ({
			token,
			form
		}) => {
			return Request.post('api/market.qycard/addmem', {
				token,
				form
			})
		},
		// 分配历史
		GetQylog: ({
			token,
			son_id,
			qy_id
		}) => {
			return Request.post('api/market.qycard/outlog', {
				token,
				son_id,
				qy_id
			})
		},
		//明细列表
		GetQydetailList: ({
			token,
			page,
			size,
			qy_id
		}, searchParams) => {
			return Request.post('api/market.qycard/qydetail', Object.assign({
				token,
				page,
				size,
				qy_id
			}, searchParams))
		},
		// 极光推送列表
		GetJpushList: ({
			token,
			id
		}) => {
			return Request.post('api/market.jpush/index', {
				token,
			})
		},
		// 添加极光推送
		AddJpush: ({
			token,
			form
		}) => {
			return Request.post('api/market.jpush/add', {
				token,
				form
			})
		},
		// 编辑极光推送
		EditJpush: ({
			token,
			form
		}) => {
			return Request.post('api/market.jpush/edit', {
				token,
				form
			})
		},
		// 删除极光推送
		DeleteJpush: ({
			token,
			id
		}) => {
			return Request.post('api/market.jpush/del', {
				token,
				id
			})
		},
		// 任务列表
		GetTaskList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.task/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 添加任务
		AddTask: ({
			token,
			form
		}) => {
			return Request.post('api/market.task/add', {
				token,
				form
			})
		},
		// 编辑任务
		EditTask: ({
			token,
			form
		}) => {
			return Request.post('api/market.task/edit', {
				token,
				form
			})
		},
		// 删除任务
		DeleteTask: ({
			token,
			id
		}) => {
			return Request.post('api/market.task/del', {
				token,
				id
			})
		},
		// 券包列表
		GetPackList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.pack/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 添加券包
		AddPack: ({
			token,
			form
		}) => {
			return Request.post('api/market.pack/add', {
				token,
				form
			})
		},
		// 编辑券包
		EditPack: ({
			token,
			form
		}) => {
			return Request.post('api/market.pack/edit', {
				token,
				form
			})
		},
		// 删除券包
		DeletePack: ({
			token,
			id
		}) => {
			return Request.post('api/market.pack/del', {
				token,
				id
			})
		},
		// 活动专区列表
		GetZoneList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/market.zone/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 添加活动专区
		AddZone: ({
			token,
			form
		}) => {
			return Request.post('api/market.zone/add', {
				token,
				form
			})
		},
		// 编辑活动专区
		EditZone: ({
			token,
			form
		}) => {
			return Request.post('api/market.zone/edit', {
				token,
				form
			})
		},
		// 删除活动专区
		DeleteZone: ({
			token,
			id
		}) => {
			return Request.post('api/market.zone/del', {
				token,
				id
			})
		},
		Coupon:{ //优惠券
			minlist: (data)=>{
				return Request.post('/api/market/coupon/minlist', data)
			}
		},
		ChargeCoupon:{ //充电返券列表
			list: (data)=>{
				return Request.post('/api/market/chargecoupon/list', data)
			},
			add: (data)=>{
				return Request.post('/api/market/chargecoupon/add', data)
			},
			edit: (data)=>{
				return Request.post('/api/market/chargecoupon/edit', data)
			},
			del: (data)=>{
				return Request.post('/api/market/chargecoupon/del', data)
			},
			record: ({token,page,size}, searchParams) => {
				return Request.post('api/market.chargecoupon/record', Object.assign({
					token,
					page,
					size
				}, searchParams))
			},
			users: ({token,page,size}, searchParams) => {
				return Request.post('api/market.chargecoupon/users', Object.assign({
					token,
					page,
					size
				}, searchParams))
			},
		},
		ActivityCommon:{ //通用活动
			list: (data)=>{
				return Request.post('/api/market/activitycommon/list', data)
			},
			add: (data)=>{
				return Request.post('/api/market/activitycommon/add', data)
			},
			edit: (data)=>{
				return Request.post('/api/market/activitycommon/edit', data)
			},
			del: (data)=>{
				return Request.post('/api/market/activitycommon/del', data)
			},
			record: ({token,page,size}, searchParams) => {
				return Request.post('api/market/activitycommon/record', Object.assign({
					token,
					page,
					size
				}, searchParams))
			},
			log: (data)=>{
				return Request.post('/api/market/activitycommon/log', data)
			},
			finish: (data)=>{
				return Request.post('/api/market/activitycommon/finish', data)
			},
			invite: (data)=>{
				return Request.post('/api/market/activitycommon/invite', data)
			},
			inviteLog: (data)=>{
				return Request.post('/api/market/activitycommon/inviteLog', data)
			},
			newcaruser: (data)=>{
				return Request.post('/api/market/activitycommon/newcaruser', data)
			},
			newcaragree: (data)=>{
				return Request.post('/api/market/activitycommon/newcaragree', data)
			},
			newcarcancel: (data)=>{
				return Request.post('/api/market/activitycommon/newcarcancel', data)
			},
		},
		Group:{ //特惠场站
			list: (data)=>{
				return Request.post('/api/market/group/list', data)
			},
			add: (data)=>{
				return Request.post('/api/market/group/add', data)
			},
			edit: (data)=>{
				return Request.post('/api/market/group/edit', data)
			},
			del: (data)=>{
				return Request.post('/api/market/group/del', data)
			},
			score: (data)=>{ //场站评分
				return Request.post('/api/market/group/score', data)
			},
			scorelist: (data)=>{ //单场站评分
				return Request.post('/api/market/group/scorelist', data)
			},
			scorevalue: (data)=>{ //场站评分设置值
				return Request.post('/api/market/group/scorevalue', data)
			},
			scoreedit: (data)=>{ //场站评分设置编辑
				return Request.post('/api/market/group/scoreedit', data)
			},
			scoredel: (data)=>{ //场站评分设置删除
				return Request.post('/api/market/group/scoredel', data)
			},
			scorereply: (data)=>{ //场站评分回复
				return Request.post('/api/market/group/scorereply', data)
			},
			report: (data)=>{ //场站报表
				return Request.post('/api/market/group/report', data)
			},
			report2: (data)=>{ //营收统计
				return Request.post('/api/market/group/report2', data)
			},
		},
		GroupPrice:{ //电站优惠
			list: (data)=>{
				return Request.post('/api/market/groupprice/index', data)
			},
			add: (data)=>{
				return Request.post('/api/market/groupprice/add', data)
			},
			edit: (data)=>{
				return Request.post('/api/market/groupprice/edit', data)
			},
			del: (data)=>{
				return Request.post('/api/market/groupprice/del', data)
			},
			data: (data)=>{
				return Request.post('/api/market/groupprice/data', data)
			},
		},
		appPublish:{ //应用推广
			list: (data)=>{
				return Request.post('/api/market.app/list', data)
			},
			users: (data)=>{
				return Request.post('/api/market.app/users', data)
			},
			add: (data)=>{
				return Request.post('/api/market.app/add', data)
			},
			edit: (data)=>{
				return Request.post('/api/market.app/edit', data)
			},
			del: (data)=>{
				return Request.post('/api/market.app/del', data)
			},
		},
		Energy:{ //能量值
			Shop:{ //商店配置
				list:(data)=>{
					return Request.post('/api/energy.shop/list', data)
				},
				add:(data)=>{
					return Request.post('/api/energy.shop/add', data)
				},
				edit:(data)=>{
					return Request.post('/api/energy.shop/edit', data)
				},
				del:(data)=>{
					return Request.post('/api/energy.shop/del', data)
				},
				log:(data)=>{
					return Request.post('/api/energy.shop/log', data)
				},
				statistics:(data)=>{
					return Request.post('/api/energy.shop/statistics', data)
				},
				finish:(data)=>{
					return Request.post('/api/energy.shop/finish', data)
				},
			},
			Group:{ //站点配置
				list:(data)=>{
					return Request.post('/api/energy.group/list', data)
				},
				add:(data)=>{
					return Request.post('/api/energy.group/add', data)
				},
				edit:(data)=>{
					return Request.post('/api/energy.group/edit', data)
				},
				del:(data)=>{
					return Request.post('/api/energy.group/del', data)
				},
			},
			Index:{
				log:(data)=>{
					return Request.post('/api/energy.index/log', data)
				},
				change:(data)=>{
					return Request.post('/api/energy.index/change', data)
				}
			},
			Business:{ //服务商家
				list:(data)=>{
					return Request.post('/api/energy.business/list', data)
				},
				view:(data)=>{
					return Request.post('/api/energy.business/view', data)
				},
				add:(data)=>{
					return Request.post('/api/energy.business/add', data)
				},
				edit:(data)=>{
					return Request.post('/api/energy.business/edit', data)
				},
				del:(data)=>{
					return Request.post('/api/energy.business/del', data)
				},
			},
		},
		yearbill:{ //年度账单
			index:(params)=>{ return Request.post('api/market.yearbill/index', params); },
			visitlist:(params)=>{ return Request.post('api/market.yearbill/visitlist', params); },
		},
		shop:{ //叮当优选 
			list:(params)=>{ return Request.post('api/market.shop/list', params); },
			add:(params)=>{ return Request.post('api/market.shop/add', params); },
			edit:(params)=>{ return Request.post('api/market.shop/edit', params); },
			order:(params)=>{ return Request.post('api/market.shop/order', params); },
			send:(params)=>{ return Request.post('api/market.shop/send', params); },
			finish:(params)=>{ return Request.post('api/market.shop/finish', params); },
			cancel:(params)=>{ return Request.post('api/market.shop/cancel', params); },
		}
	},
	Agent: {
		// 代理商列表
		GetAgentList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/agent.agent/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 代理商详情
		GetAgentDetail: ({
			token,
			agent_id
		}) => {
			return Request.post('api/agent.agent/view', {
				token,
				agent_id
			})
		},
		// 编辑代理商
		EditAgent: ({
			token,
			form
		}) => {
			return Request.post('api/agent.agent/edit', {
				token,
				form
			})
		},
		// 结算列表
		GetAccountList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/agent.agent/account', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 结算
		CheckAccount: ({
			token,
			form
		}) => {
			return Request.post('api/agent.agent/check', {
				token,
				form
			})
		},
		// 结算列表
		GetOperatorList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/agent.operator/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 添加组织机构
		AddOperator: ({
			token,
			form
		}) => {
			return Request.post('api/agent.operator/add', {
				token,
				form
			})
		},
		// 代理商详情
		GetOperatorDetail: ({
			token,
			id
		}) => {
			return Request.post('api/agent.operator/view', {
				token,
				id
			})
		},
		// 编辑代理商
		EditOperator: ({
			token,
			form
		}) => {
			return Request.post('api/agent.operator/edit', {
				token,
				form
			})
		},
		// 删除
		DeleteOperator: ({
			token,
			id
		}) => {
			return Request.post('api/agent.operator/del', {
				token,
				id
			})
		}
	},
	Fans: {
		EnableFans: (data) => {
			return Request.post('/api/user/fans/enable', data)
		},
		DisableFans: (data) => {
			return Request.post('/api/user/fans/disable', data)
		},
		// 用户列表
		GetFansList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.fans/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		GetCarDetail: ({
			token,
			fans_id
		}) => {
			return Request.post('api/user.fans/getCardetail', {
				token,
				fans_id
			})
		},
		GetConDetail: ({
			token,
			fans_id
		}) => {
			return Request.post('api/user.fans/getCondetail', {
				token,
				fans_id
			})
		},
		GetCardDetail: ({
			token,
			fans_id
		}) => {
			return Request.post('api/market.card/getCondetail', {
				token,
				fans_id
			})
		},
		// 用户详情
		GetFansDetail: ({
			token,
			fans_id
		}) => {
			return Request.post('api/user.fans/view', {
				token,
				fans_id
			})
		},
		// 设置白名单
		SetFansIsWhite: ({
			token,
			fans_id,
			is_white
		}) => {
			return Request.post('api/user.fans/setWhite', {
				token,
				fans_id,
				is_white
			})
		},
		// 充值
		RechargeFans: ({
			token,
			form
		}) => {
			return Request.post('api/user.fans/recharge', {
				token,
				form
			})
		},
		// 赠送金列表
		GetGiftlogList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.fans/giftlog', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 充值订单列表
		GetRechargeList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.recharge/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 充值订单详情
		GetRechargeDetail: ({
			token,
			order_id
		}) => {
			return Request.post('api/user.recharge/view', {
				token,
				order_id
			})
		},
		// 退款充值订单
		RefundRecharge: ({
			token,
			form
		}) => {
			return Request.post('api/user.recharge/refund', {
				token,
				form
			})
		},
		// 审核退款订单
		Refund: ({
			token,
			form
		}) => {
			return Request.post('api/user.recharge/agree', {
				token,
				form
			})
		},
		// 审核退款订单
		Refundrefuse: ({
			token,
			form
		}) => {
			return Request.post('api/user.recharge/refuse', {
				token,
				form
			})
		},
		// 消费订单列表
		GetOrderList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.order/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 消费订单列表
		GetSeatOrderList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.seatorder/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 消费订单详情
		GetOrderDetail: ({
			token,
			order_id
		}) => {
			return Request.post('api/user.order/view', {
				token,
				order_id
			})
		},
		// 私桩订单详情
		GetPrivateOrderDetail: ({
			token,
			order_id
		}) => {
			return Request.post('api/user.order/viewprivate', {
				token,
				order_id
			})
		},
		// 退款消费订单
		RefundOrder: ({
			token,
			form
		}) => {
			return Request.post('api/user.order/refund', {
				token,
				form
			})
		},
		// 结束订单退款
		EndRefund: (params) => {
			return Request.post('api/user.order/endrefund', params)
		},
		// 退款消费订单
		RefundSeatOrder: ({
			token,
			sit_id,
			uid,
			pay_type,
			pay_price
		}) => {
			return Request.post('api/user.seatorder/refund', {
				token,
				sit_id,
				uid,
				pay_type,
				pay_price
			})
		},
		// 消费订单交互日志
		GetWorkOrder: ({
			token,
			order_id
		}) => {
			return Request.post('api/user.order/workorder', {
				token,
				order_id
			})
		},
		// 私桩订单交互日志
		GetprivateOrder: ({
			token,
			order_id,
			type
		}) => {
			return Request.post('api/user.order/workorder', {
				token,
				order_id,
				type
			})
		},
		// 退款列表
		GetRefundList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.order/refundindex', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 老系统同步余额列表
		GetOldBalance: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.fans/oldBalance', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 退款审核列表
		GetRefund: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.order/refundexamine', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 用户扣款
		ChargingFans: ({
			token,
			form
		}) => {
			return Request.post('api/user.fans/charging', {
				token,
				form
			})
		},
		fansother:{ //采集信息
			list:(params)=>{
				return Request.post('api/user.fansother/list', params);
			},
			getuserlist:(params)=>{
				return Request.post('api/user.fansother/getuserlist', params);
			}
		},
		white:{ //白名单
			list:(params)=>{ return Request.post('api/user.fans/whitelist', params); },
			add:(params)=>{ return Request.post('api/user.fans/whiteadd', params); },
			delete:(params)=>{ return Request.post('api/user.fans/whitedelete', params); },
		}
	},
	Cards: {
		// 电卡列表
		GetCardsList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.cards/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 编辑卡信息
		EditCard: ({
						 token,
						 form
					 }) => {
			return Request.post('api/user.cards/editCard', {
				token,
				form
			})
		},
		// 异常电卡信息
		GetExceptionCardsList: (data) => {
			return Request.post('api/user/cards/abnormal_cards', data)
		},
		// 电卡日志信息
		GetCardsLogs: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/user.cards/log', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 电卡详情
		GetCardsDetail: ({
			token,
			card_id
		}) => {
			return Request.post('api/user.cards/view', {
				token,
				card_id
			})
		},
		// 卡虚拟账户充值
		RechargeFans: ({
			token,
			form
		}) => {
			return Request.post('api/user.cards/recharge', {
				token,
				form
			})
		},
		// 用户扣款
		ChargingFans: ({
			token,
			form
		}) => {
			return Request.post('api/user.cards/charging', {
				token,
				form
			})
		},
		// 添加在线金额卡
		AddCard: (params) => {
			return Request.post('api/user.cards/add', params)
		},
		// 添加在线金额卡
		bandAccount: (params) => {
			return Request.post('api/user.cards/bandaccount', params)
		},
		// 卡
		ShellCard: (data) => {
			return Request.post('api/wechart.shell/card', data)
		},
		// 恢复挂失卡
		recoveryCard: (data) => {
			return Request.post('/api/wechart.shell/recoveryCard', data);
		}
	},
	Rf: {
		// 相机列表
		GetRfList: ({
						   token,
						   page,
						   size
					   }, searchParams) => {
			return Request.post('api/monitor.Rf/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 编辑相机
		EditRf: (params) => {
			return Request.post('api/monitor.Rf/edit', params)
		},
		// 添加在线金额卡
		AddRf: (params) => {
			return Request.post('api/monitor.Rf/add', params)
		},
		// 删除相机
		DelRf: (params) => {
			return Request.post('api/monitor.Rf/del', params)
		},
		// 查询监控
		GetmPark: (params) => {
			return Request.post('api/monitor.rfcamera/mPark', params)
		},
		//查询车位信息
		GetParkInfo: (params) => {
			return Request.post('api/monitor.rfcamera/getParkDetail', params)
		},
		// 相机列表
		GetParkList: ({
						token,
						page,
						size,
						sn_no
					}, searchParams) => {
			return Request.post('api/monitor.Rf/getParkList', Object.assign({
				token,
				page,
				size,
				sn_no
			}, searchParams))
		},
	},
	Bill: {
		GetGroupBillList: (data) => {
			return Request.post('api/group.bill/index', data);
		},
		AddGroupBill: (data) => {
			return Request.post('api/group.bill/add', data);
		},
		GetPropertyBillList: (data) => {
			return Request.post('api/group.wuye/index', data);
		},
		AddPropertyBill: (data) => {
			return Request.post('api/group.wuye/add', data);
		},
		PropertyPay: (data) => {
			return Request.post('api/group.wuye/payment', data);
		},
		showbyid: (data) => {
			return Request.post('api/group.wuye/view', data);
		},
		editWuye: (data) => {
			return Request.post('api/group.wuye/edit', data);
		},
	},
	Report: {
		// 小区考核
		GetGroupExamine: ({
			token,
			form
		}) => {
			return Request.post('api/report.group/examine', {
				token,
				form
			})
		},
		// 小区消费
		GetGroupView: ({
			token,
			form
		}) => {
			return Request.post('api/report.group/view', {
				token,
				form
			})
		}, // 小区消费
		GetGroupViewA: ({
			token,
			form
		}) => {
			return Request.post('api/report.group/viewa', {
				token,
				form
			})
		}, // 小区消费
		GetGroupViewB: ({
			token,
			form
		}) => {
			return Request.post('api/report.group/viewb', {
				token,
				form
			})
		},
		GetAlarmList: ({
				 token,
				 page,
				 size
			 }, searchParams) => {
				return Request.post('api/report.alarm/index', Object.assign({
					token,
					page,
					size
				}, searchParams))
			},
		// 处理预警
		DoAlarm: ({
						   token,
						   form
					   }) => {
			return Request.post('api/report.alarm/doalarm', {
				token,
				form
			})
		},
		Profit:{ //站点利润
			List: params => Request.post('api/report.profit/list', params),
			Add: params => Request.post('api/report.profit/add', params),
			Edit: params => Request.post('api/report.profit/edit', params),
			Del: params => Request.post('api/report.profit/del', params),
			StatisticsList: params => Request.post('api/report.profit/statisticslist', params),
			StatisticsAdd: params => Request.post('api/report.profit/statisticsadd', params),
			StatisticsEdit: params => Request.post('api/report.profit/statisticsedit', params),
			StatisticsDel: params => Request.post('api/report.profit/statisticsdel', params),
		},
		GroupShare:{ //共建管理
			list: params => Request.post('api/report.group_share/list', params),
			add: params => Request.post('api/report.group_share/add', params),
			edit: params => Request.post('api/report.group_share/edit', params),
			del: params => Request.post('api/report.group_share/del', params),
			settlelist: params => Request.post('api/report.group_share/settlelist', params),
			settleadd: params => Request.post('api/report.group_share/settleadd', params),
			settleedit: params => Request.post('api/report.group_share/settleedit', params),
			settledel: params => Request.post('api/report.group_share/settledel', params),
			settlesync: params => Request.post('api/report.group_share/settlesync', params),
			settleapply: params => Request.post('api/report.group_share/settleapply', params),
			settlecancel: params => Request.post('api/report.group_share/settlecancel', params),
			settleend: params => Request.post('api/report.group_share/settleend', params),
			settleview: params => Request.post('api/report.group_share/settleview', params),
		}
	},
	Ad: {
		// 广告列表
		GetAdList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/adverts.adlist/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		//新增广告
		AddAd: (data) => {
			return Request.post('api/adverts.adlist/add', data);
		},
		// 广告详情
		GetAdDetail: ({
			token,
			ad_id
		}) => {
			return Request.post('api/adverts.adlist/view', {
				token,
				ad_id
			})
		},
		editGroupBill: (data) => {
			return Request.post('api/adverts.adlist/save', data);
		},
		// 删除广告
		DeleteAd: ({
			token,
			ad_id,
			attach_id
		}) => {
			return Request.post('api/adverts.adlist/delete', {
				token,
				ad_id,
				attach_id
			})
		}, // 删除广告
		DeleteImg: ({
			token,
			attach_id
		}) => {
			return Request.post('api/adverts.adlist/deleteimg', {
				token,
				attach_id
			})
		},
		upStatus: (data) => {
			return Request.post('api/adverts.adlist/upstatus', data);
		},
	},
	Ware: {
		// 消费订单列表
		GetOrderList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/nh.order/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		GetXOrderList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/xing.order/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		GetPrivatesList: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('api/privates.order/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		// 消费订单详情
		GetOrderDetail: ({
			token,
			order_id
		}) => {
			return Request.post('api/nh.order/view', {
				token,
				order_id
			})
		},
		// 退款消费订单
		RefundOrder: ({
			token,
			form
		}) => {
			return Request.post('api/nh.order/refund', {
				token,
				form
			})
		},
		// 消费订单交互日志
		GetWorkOrder: ({
			token,
			order_id
		}) => {
			return Request.post('api/nh.order/workorder', {
				token,
				order_id
			})
		},
	},
	Fifa: { //世界杯
		TeamIndex: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('/api/fifa/team/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		TeamAdd: (data) => {
			return Request.post('/api/fifa/team/add', data)
		},
		TeamEdit: (data) => {
			return Request.post('/api/fifa/team/edit', data)
		},
		TeamDel: (data) => {
			return Request.post('/api/fifa/team/del', data)
		},
		MatchIndex: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('/api/fifa/matchs/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		MatchAdd: (data) => {
			return Request.post('/api/fifa/matchs/add', data)
		},
		MatchResult: (data) => {
			return Request.post('/api/fifa/matchs/results', data)
		},
		MatchEdit: (data) => {
			return Request.post('/api/fifa/matchs/edit', data)
		},
		MatchDel: (data) => {
			return Request.post('/api/fifa/matchs/del', data)
		},
		BetDetail: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('/api/fifa/bet/detailed', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		BetRank: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('/api/fifa/bet/ranking', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		BetSummary: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('/api/fifa/bet/summary', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
	},
	Invoice: { //发票
		InvoiceIndex: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('/api/user/invoice/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		InvoiceEdit: (data) => {
			return Request.post('/api/user/invoice/edit', data)
		},
		InvoiceDetail: (data) => {
			return Request.post('/api/user/invoice/detail', data)
		},
	},
	Vip: { //VIP
		GiftsIndex: ({
			token,
			page,
			size
		}, searchParams) => {
			return Request.post('/api/vip/gifts/index', Object.assign({
				token,
				page,
				size
			}, searchParams))
		},
		GiftsAdd: (data) => {
			return Request.post('/api/vip/gifts/add', data)
		},
		GiftsEdit: (data) => {
			return Request.post('/api/vip/gifts/edit', data)
		},
		GiftsDel: (data) => {
			return Request.post('/api/vip/gifts/del', data)
		},
		VipcfgIndex: ({
			token,
			fieldCode
		}, searchParams) => {
			return Request.post('/api/vip/vipcfg/index', Object.assign({
				token,
				fieldCode
			}, searchParams))
		},
		VipcfgEdit: (data) => {
			return Request.post('/api/vip/vipcfg/edit', data)
		},
		VipcfgEditZk: (data) => {
			return Request.post('/api/vip/vipcfg/editzk', data)
		},
		VipcfgEditSq: (data) => {
			return Request.post('/api/vip/vipcfg/editsq', data)
		},
		VipcfgEditXshd: (data) => {
			return Request.post('/api/vip/vipcfg/editxshd', data)
		},
		Record: (data) => {
			return Request.post('/api/vip/gifts/record', data)
		},
		VipcfgBoxLog: (data) => {
			return Request.post('/api/vip/vipcfg/boxlog', data)
		},
	},
	Hlht:{ //互联互通
		Manager:{ //站点推送
			operatorlist: (data)=>{ //组织代码列表
				return Request.post('/api/hlht/manager/operatorlist', data)
			},
			list: (data)=>{
				return Request.post('/api/hlht/manager/list', data)
			},
			edit: (data)=>{
				return Request.post('/api/hlht/manager/edit', data)
			},
			detail: (data)=>{
				return Request.post('/api/hlht/manager/detail', data)
			},
			detailedit: (data)=>{
				return Request.post('/api/hlht/manager/detailedit', data)
			},
			order: (data)=>{
				return Request.post('/api/hlht/manager/order', data)
			},
			orderdetail: (data)=>{
				return Request.post('/api/hlht/manager/orderdetail', data)
			},
			orderEnd: (data)=>{
				return Request.post('/api/hlht/manager/orderend', data)
			},
		}
	}
}
export default API
