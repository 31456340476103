<template>
  <div
    class="side-wrap"
    :class="{show: sideFlag}"
  >
    <div
      class="pop"
      @click="hideSide"
    ></div>
    <el-aside
      class="side"
      width="220px"
    >
      <div class="side-logo">
        <el-image
          class="logo"
          :src="logoSrc"
        ></el-image>
        <p>叮当充电</p>
      </div>
      <el-menu
        class="el-menu"
        active-text-color="#ee8812"
        :default-active="activeMenu"
        :unique-opened="true"
        :router="true"
      >
        <el-submenu
          v-for="item in menuList"
          :key="item.name"
          :index="item.name"
        >
          <template slot="title">
            <i
              id="sub-icon"
              :class="item.icon"
            ></i>
            <span id="sub-name">{{item.meta.title}}</span>
          </template>
          <el-menu-item
            v-if="item.children && !subItem.meta.hideInMenu"
            v-for="subItem in item.children"
            :key="subItem.name"
            :index="subItem.name"
          >
            {{subItem.meta.title}}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
import logoSrc from '@/assets/logo.png'

export default {
  name: 'side',
  data () {
    return {
      logoSrc
    }
  },
  computed: {
    sideFlag () {
      return this.$store.state.app.sideFlag
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    activeMenu () {
      return this.$route.name
    },
    menuList () {
      return this.$store.state.user.menuRouters
    }
  },
  methods: {
    hideSide () {
      this.$store.commit('hideSide')
    }
  }
}
</script>

<style lang="scss">
.el-submenu__title {
  display: flex;
  align-items: center;

  &:hover {
    color: #008e4d !important;
    background-color: #9bcdb6 !important;

    i {
      color: #008e4d !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.side-wrap {
  height: 100%;
  position: relative;
  z-index: 9;
}
.side {
  height: 100%;
  /*border-right: 1px solid #ee8812;*/
  box-shadow: 0 2px 10px rgba(0, 21, 41, 0.08);
  position: relative;
  z-index: 9;

  .side-logo {
    margin: 0 10px;
    font-size: 24px;
    display: flex;
    align-items: center;

    .logo {
      width: 44px;
      margin-right: 10px;
    }
  }
}

.el-menu {
  border-right: 0;
}

.el-menu-item {
  &:hover {
    color: #008e4d !important;
  }
}

.el-menu-item.is-active {
  background-color: #9bcdb6 !important;
  border-right: 3px solid #008e4d;
  color: #008e4d !important;
}
</style>

<style lang="scss" scoped>
// 小屏幕响应
@media screen and (max-width: 767px) {
  .side-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    visibility: hidden;
    &.show {
      visibility: visible;
    }
  }
  .side {
    background: #fff;
  }
  .pop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.2);
  }
}
</style>
